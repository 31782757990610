<template>
  <v-app :style="getImage">
    <Navbar v-if="(usuario || user) && this.$route.name != 'ingreso' && 
    this.$route.name != 'resetpassword'"></Navbar>
<!-- v-if="usuario || user" -->
    <v-content>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-content>

    <v-snackbar
    v-for="(snackbar, index) in snackbars.filter(s => s.showing)"
    :key="snackbar.text + Math.random()"
    :style="`top: ${index * 60 + 8}px`"
    :timeout="timeout"
    right top :color="snackbar.color" v-model="snackbar.showing">
      {{ snackbar.text }}
      <v-btn text @click="snackbar.showing = false">Cerrar</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import { mapState } from "vuex";
import bg from './assets/fondo1.jpg';
export default {
  components: { Navbar },
  name: "App",
  data() {
    return {
      user: localStorage.usuario || '',
      timeout: 2000,
    };
  },
  computed: {
    ...mapState(["snackbars", "usuario"]),
    getImage() {
      
      if (this.$route.name != 'ingreso' && this.$route.name != 'resetpassword') {
        return false;
      }
     return {'background-image':"url(" + bg + ") !important", "background-size":"cover"}
    //  return {'background-image':"url(" + bg + ") !important"}
    }
  },
  created() {
    this.user = localStorage.usuario
  }
};
</script>

<style lang="scss">
  .v-dialog {
    border-radius: 35px !important;
  }

  ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #8D4925;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #8D4925 !important;
  border-radius: 10px;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #8D4925;
  box-shadow: none;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

@media (min-width: 1264px) {
  .container {
    max-width: 1700px;
  }
}
</style>